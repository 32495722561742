<template lang="">
    <div class="p-5" >
        <b-card no-body header="ລາຍງານປະຈຳວັນ">
    <b-list-group flush>
      <b-list-group-item  v-for="(item,index) in items"   _blank  :key="item.id"  class="d-flex justify-content-between  "   :to="'/listregister?queue_date1='+item.queue_date1" >
        <b-avatar :text="index+1"></b-avatar>  {{item.unit_name}} <p>{{item.queue_date}}</p>  <p  class="text-primary" pill>   {{item.total}} ຄົນ     </p>  
        <b-icon icon="caret-right-fill" pill></b-icon>
        
      </b-list-group-item>
 
    </b-list-group> 
 
  </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
  data () {
    return {
        items:[]
    }
  },
  created () {
    axios
            .get(
                this.$store.getters.getBaseUrl + "/v_total_queues/1/1",
                {
                  headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
          "Accept-Encoding": "identity"
        }
                }
            )
            .then((response) => {
                // JSON responses are automatically parsed.
                this.items = response.data

            })
  },
    
}
</script>
<style lang="">
    
</style>